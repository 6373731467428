.bnpp-process-list-module  {
    display: flex;
    flex-wrap: wrap;
    font-family: var(--wp--preset--font-family--bnpp-sans-condensed);
    padding: var(--wp--preset--spacing--40);
    background-color: var(--wp--preset--color--bnpp-green-tint-15);

    .bnpp-process-list-module-content {
        flex: 1 1 120px;
    }
    
    .number {
        background-color: var(--wp--preset--color--bnpp-green-tint-20);
        border: 1px solid currentColor;
        padding-top: 12px;
        height: fit-content;
        margin-right: var(--wp--preset--spacing--40);
        font-weight: 400;
        font-size: var(--wp--preset--font-size--heading-l);
        line-height: 1;
        flex: 0 1 80px;
        display: grid;
        place-content: center;
    }

    .bnpp-process-list-module-title {
        font-size: var(--wp--preset--font-size--heading-s);
        margin: var(--wp--preset--spacing--20) 0;
    }

    .bnpp-process-list-module-text {
        font-size: var(--wp--preset--font-size--body);
        font-family: var(--wp--preset--font-family--bnpp-sans);
        margin-top: 0;
    }
    
}